define("ember-indexeddb/services/indexed-db-configuration", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/array", "@ember/debug"], function (_exports, _service, _object, _utils, _array, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  /**
   * This service should be overwritten to configure IndexedDB.
   * Overwrite the `mapTable` property & add `versionX` properties to fit your application.
   *
   * @module Services
   * @class IndexedDbConfiguration
   * @extends Ember.Service
   * @public
   */
  class IndexedDbConfigurationService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "currentVersion", 0);
      _defineProperty(this, "mapTable", {});
    }
    /**
     * Map a payload to a database table.
     * This will use the function provided in mapTable to get a payload to insert into IndexedDB.
     * Returns null if no map function is found for the type.
     *
     * @method mapItem
     * @param {String} type The type of object to map
     * @param {Object} item The data to map
     * @return {Object}
     * @public
     */
    mapItem(type, item) {
      let tables = this.mapTable;
      let mapFunc = (0, _object.get)(tables, type);
      if (!item) {
        return null;
      }
      if (!mapFunc) {
        return {
          id: this._toString((0, _object.get)(item, 'id')),
          json: this._cleanObject(item)
        };
      }
      return mapFunc(item);
    }

    /**
     * Setup the database and do all necessary database migrations.
     *
     * @method setupDatabase
     * @param {Dexie} db
     * @return {Dexie}
     * @public
     */
    setupDatabase(db) {
      let currentVersion = this.currentVersion;
      (true && !(currentVersion) && (0, _debug.assert)('You need to override services/indexed-db-configuration.js and provide at least one version.', currentVersion));
      for (let v = 1; v <= currentVersion; v++) {
        let version = (0, _object.get)(this, `version${v}`);
        let stores = (0, _object.get)(version, 'stores');
        let upgrade = (0, _object.get)(version, 'upgrade');
        if (stores && upgrade) {
          db.version(v).stores(stores).upgrade(upgrade);
        } else if (stores) {
          db.version(v).stores(stores);
        } else if (upgrade) {
          db.version(v).upgrade(upgrade);
        }
      }
      return db;
    }

    /**
     * Cleanup a json object.
     * This will convert array-like structures to actual arrays for saving.
     * It will strip out meta properties etc.
     *
     * @method _cleanObject
     * @param {Object} data
     * @return {{id, type, attributes: {}, relationships: {}}}
     * @private
     */
    _cleanObject(data) {
      if (!data) {
        return null;
      }
      let obj = {
        id: (0, _object.get)(data, 'id'),
        type: (0, _object.get)(data, 'type'),
        attributes: {},
        relationships: {}
      };
      let attributes = (0, _object.get)(data, 'attributes') || {};
      let relationships = (0, _object.get)(data, 'relationships') || {};
      let isArray = item => {
        return (0, _utils.typeOf)(item) === 'array' || (0, _utils.typeOf)(item) === 'instance' && (0, _utils.typeOf)(item.toArray) === 'function';
      };
      Object.keys(attributes).forEach(prop => {
        // Convert array-like structures to real arrays
        if (isArray(attributes[prop])) {
          obj.attributes[prop] = (0, _array.A)(attributes[prop]).toArray();
        } else {
          obj.attributes[prop] = attributes[prop];
        }
      });
      Object.keys(relationships).forEach(prop => {
        if (isArray(relationships[prop].data)) {
          obj.relationships[prop] = {
            data: (0, _array.A)(relationships[prop].data).toArray()
          };
        } else {
          obj.relationships[prop] = relationships[prop];
        }
      });
      return obj;
    }

    /**
     * Convert a property to a string.
     *
     * @method _toString
     * @param {Mixed} val
     * @return {String}
     * @private
     */
    _toString(val) {
      return `${val}`;
    }

    /**
     * Convert a boolean to 1/0.
     * Optionally, you can specify the value that should be used if the given value does not exist in the payload.
     * For example, if you want that a given value should be 1 if not found in the payload, use `this._toZeroOne(value, 1)`.
     *
     * @method _toZeroOne
     * @param {Mixed} val
     * @param {0|1} noneValue The value to use if val is null/undefined.
     * @return {1|0}
     * @private
     */
    _toZeroOne(val) {
      let noneValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      if ((0, _utils.isNone)(val)) {
        return noneValue;
      }
      return val ? 1 : 0;
    }
  }
  _exports.default = IndexedDbConfigurationService;
});