define("ember-indexeddb/utils/clone-deep", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cloneDeep = cloneDeep;
  _exports.default = void 0;
  function isObject(obj) {
    return (0, _utils.typeOf)(obj) === 'object' && obj.constructor === Object;
  }
  function isArray(obj) {
    return (0, _utils.typeOf)(obj) === 'array';
  }
  function isCloneableInstance(obj) {
    return (0, _utils.typeOf)(obj) === 'instance' && (0, _utils.typeOf)(obj.clone) === 'function';
  }

  /**
   * Deep-clone an object.
   *
   * @namespace EmberIndexeddb.Util
   * @method cloneDeep
   * @param {Object} obj An object to clone deeply
   * @return {Object} The cloned object
   * @public
   */
  function cloneDeep(obj) {
    let data = {};
    Object.keys(obj).forEach(key => {
      let value = obj[key];
      data[key] = cloneValue(value);
    });
    return data;
  }
  var _default = cloneDeep;
  _exports.default = _default;
  function cloneValue(value) {
    if (isArray(value)) {
      return cloneArray(value);
    }
    if (isObject(value)) {
      return cloneDeep(value);
    }
    if (isCloneableInstance(value)) {
      return value.clone();
    }
    return value;
  }
  function cloneArray(value) {
    return value.map(cloneValue);
  }
});