define("ember-indexeddb/utils/test-waiter", ["exports", "@ember/test", "ember"], function (_exports, _test, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerWaiter = registerWaiter;
  _exports.unregisterWaiter = unregisterWaiter;
  // Wrap these, so they are only called when testing
  function registerWaiter() {
    if (_ember.default.testing) {
      return (0, _test.registerWaiter)(...arguments);
    }
  }
  function unregisterWaiter() {
    if (_ember.default.testing) {
      return (0, _test.unregisterWaiter)(...arguments);
    }
  }
});