define("ember-indexeddb/adapters/indexed-db", ["exports", "@ember/service", "@ember/object", "rsvp", "@ember/array", "ember-indexeddb/utils/clone-deep", "@ember-data/adapter/json-api"], function (_exports, _service, _object, _rsvp, _array, _cloneDeep, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   *
   * This Ember-Data adapter will fetch and save all data to/from IndexedDB.
   *
   * @module Ember Data
   * @class IndexedDbAdapter
   * @extends DS.JSONAPIAdapter
   * @public
   */
  let IndexedDbAdapter = (_class = class IndexedDbAdapter extends _jsonApi.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "indexedDb", _descriptor, this);
      _defineProperty(this, "coalesceFindRequests", true);
      _defineProperty(this, "_shouldLogDurations", false);
    }
    /**
     * This function will generate a GUID to be used in IndexedDB.
     *
     * @method generateIdForRecord
     * @return {String}
     * @protected
     */
    generateIdForRecord() {
      return Math.random().toString(32).slice(2).substr(0, 8);
    }

    /**
     * Fetch all records of a given type from IndexedDB.
     *
     * @method findAll
     * @param store
     * @param type
     * @return {Promise}
     * @public
     */
    findAll(store, type) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      this._logDuration(`findAll ${modelName}`);
      return new _rsvp.Promise((resolve, reject) => {
        indexedDB.findAll(modelName).then(records => {
          this._logDuration(`findAll ${modelName}`, true);
          let data = this._normalizeArray(records);
          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findAll');
    }

    /**
     * Find a record of a given type & ID from IndexedDB.
     *
     * @method findRecord
     * @param store
     * @param type
     * @param {String} id
     * @return {Promise}
     * @public
     */
    findRecord(store, type, id) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      this._logDuration(`findRecord ${modelName}/${id}`);
      return new _rsvp.Promise((resolve, reject) => {
        indexedDB.find(modelName, id).then(record => {
          this._logDuration(`findRecord ${modelName}/${id}`, true);
          let data = this._normalizeSingle(record);
          if (!data) {
            reject(`findRecord ${modelName}/${id} failed!`);
            return;
          }
          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findRecord');
    }

    /**
     * Find many records for a given type by multiple IDs.
     *
     * @method findMany
     * @param store
     * @param type
     * @param {Array} ids
     * @return {Promise}
     * @public
     */
    findMany(store, type, ids) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      this._logDuration(`findMany ${modelName}/${ids.join(',')}`);
      return new _rsvp.Promise((resolve, reject) => {
        indexedDB.find(modelName, ids).then(records => {
          this._logDuration(`findMany ${modelName}/${ids.join(',')}`, true);
          let data = this._normalizeArray(records);
          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/findMany');
    }

    /**
     * Query a type from IndexedDB.
     * This will try to use real indices where possible.
     *
     * @method query
     * @param store
     * @param type
     * @param {Object} query
     * @return {Promise}
     * @public
     */
    query(store, type, query) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      let queryString = JSON.stringify(query);
      this._logDuration(`query ${modelName} ${queryString}`);
      return new _rsvp.Promise((resolve, reject) => {
        indexedDB.query(modelName, query).then(records => {
          this._logDuration(`query ${modelName} ${queryString}`, true);
          let data = this._normalizeArray(records);
          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/query');
    }

    /**
     * Query a single item from IndexedDB.
     * This will try to use real indices where possible.
     *
     * @method queryRecord
     * @param store
     * @param type
     * @param {Object} query
     * @return {Promise}
     * @public
     */
    queryRecord(store, type, query) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      this._logDuration(`queryRecord ${modelName}`);
      return new _rsvp.Promise((resolve, reject) => {
        indexedDB.queryRecord(modelName, query).then(record => {
          this._logDuration(`queryRecord ${modelName}`, true);
          let data = this._normalizeSingle(record);
          if (!data) {
            resolve({
              data: null
            });
            return;
          }
          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/queryRecord');
    }

    /**
     * Update a given record in IndexedDB.
     *
     * @method updateRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    updateRecord(store, type, snapshot) {
      return this._save(store, type, snapshot);
    }

    /**
     * Delete a record from IndexedDB.
     *
     * @method deleteRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    deleteRecord(store, type, snapshot) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      let id = (0, _object.get)(snapshot, 'id');
      this._logDuration(`deleteRecord ${modelName}/${id}`);
      return new _rsvp.Promise((resolve, reject) => {
        indexedDB.delete(modelName, id).then(() => {
          this._logDuration(`deleteRecord ${modelName}/${id}`, true);
          resolve(null);
        }, reject);
      }, 'indexedDbAdapter/deleteRecord');
    }

    /**
     * Create a new record in IndexedDB.
     *
     * @method createRecord
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @public
     */
    createRecord(store, type, snapshot) {
      return this._save(store, type, snapshot);
    }

    /**
     * This function is called under the hood by both `createRecord` and `updateRecord`.
     *
     * @method _save
     * @param store
     * @param type
     * @param snapshot
     * @return {Promise}
     * @private
     */
    _save(store, type, snapshot) {
      let indexedDB = this.indexedDb;
      let {
        modelName
      } = type;
      this._logDuration(`_save ${modelName}/${(0, _object.get)(snapshot, 'id')}`);
      return new _rsvp.Promise((resolve, reject) => {
        let data = {};
        let serializer = store.serializerFor(modelName);
        serializer.serializeIntoHash(data, type, snapshot, {
          includeId: true
        });
        // We need to make a deep clone of the data, as the data is mutated by the store later
        let record = (0, _cloneDeep.cloneDeep)(data.data);
        indexedDB.save(modelName, record.id, record).then(() => {
          this._logDuration(`_save ${modelName}/${(0, _object.get)(snapshot, 'id')}`, true);
          resolve(data);
        }, reject);
      }, 'indexedDbAdapter/_save');
    }

    /**
     * This is used to normalize the response of IndexedDB for array responses.
     *
     * @method _normalizeArray
     * @param records
     * @return {Object}
     * @private
     */
    _normalizeArray(records) {
      if (!records) {
        return {
          data: []
        };
      }
      let data = (0, _array.A)(records).compact();
      data = (0, _array.A)(data).mapBy('json');
      return {
        data
      };
    }

    /**
     * This is used to normalize a single record response.
     *
     * @method _normalizeSingle
     * @param record
     * @return {Object}
     * @private
     */
    _normalizeSingle(record) {
      if (!record) {
        return null;
      }
      return {
        data: (0, _object.get)(record, 'json')
      };
    }

    /**
     * This function is used to log durations of operations to the console, if `_shouldLogDurations` is set.
     *
     * @method _logDuration
     * @param str
     * @param isEnd
     * @private
     */
    _logDuration(str) {
      let isEnd = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!this._shouldLogDurations) {
        return;
      }

      /* eslint-disable no-console */
      if (isEnd) {
        console.timeEnd(str);
      } else {
        console.time(str);
      }
      /* eslint-enable no-console */
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "indexedDb", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = IndexedDbAdapter;
});