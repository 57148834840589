define("ember-indexeddb/utils/log", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.log = log;
  // Log a message, unless in tests (to prevent noisy test output)
  function log() {
    if (!_ember.default.testing) {
      console.log(...arguments); // eslint-disable-line
    }
  }
});