define("ember-indexeddb/services/indexed-db", ["exports", "@ember/service", "@ember/object", "rsvp", "@ember/runloop", "@ember/utils", "@ember/array", "ember-indexeddb/utils/test-waiter", "ember-concurrency", "ember-indexeddb/utils/log", "dexie"], function (_exports, _service, _object, _rsvp, _runloop, _utils, _array, _testWaiter, _emberConcurrency, _log, _dexie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * This service allows interacting with an IndexedDB database.
   *
   * @module Services
   * @class IndexedDb
   * @extends Ember.Service
   * @public
   */
  let IndexedDbService = (_dec = (0, _emberConcurrency.task)(function* () {
    if ((0, _object.get)(this, 'db')) {
      return (0, _object.get)(this, 'db');
    }
    let testWaiter = function () {
      return false;
    };
    (0, _testWaiter.registerWaiter)(testWaiter);
    let db = new _dexie.default((0, _object.get)(this, 'databaseName'));
    let dbConfiguration = (0, _object.get)(this, 'indexedDbConfiguration');
    dbConfiguration.setupDatabase(db);
    (0, _object.set)(this, 'db', db);
    yield openDb(db);
    (0, _testWaiter.unregisterWaiter)(testWaiter);
    return db;
  }), _dec2 = (0, _emberConcurrency.task)(function* () {
    let db = (0, _object.get)(this, 'db');
    if (!db) {
      return;
    }
    let testWaiter = function () {
      return false;
    };
    (0, _testWaiter.registerWaiter)(testWaiter);

    // Ensure the db is open
    yield openDb(db);
    yield (0, _object.get)(this, 'waitForQueueTask').perform();
    yield (0, _emberConcurrency.timeout)(100);
    yield db.delete();
    yield closeDb(db);
    (0, _object.set)(this, 'db', null);
    (0, _testWaiter.unregisterWaiter)(testWaiter);
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    let db = (0, _object.get)(this, 'db');
    let config = {
      databaseName: (0, _object.get)(this, 'databaseName'),
      version: (0, _object.get)(this, 'currentVersion'),
      stores: {},
      data: {}
    };

    // Now, open database without specifying any version. This will make the database open any existing database and read its schema automatically.
    yield openDb(db);

    // Save the last version number
    (0, _object.set)(config, 'version', db.verno);
    let promises = (0, _array.A)();
    db.tables.forEach(function (table) {
      let primKeyAndIndexes = [table.schema.primKey].concat(table.schema.indexes);
      let schemaSyntax = primKeyAndIndexes.map(function (index) {
        return index.src;
      }).join(',');
      (0, _object.set)(config.stores, table.name, schemaSyntax);
      let promise = table.each(object => {
        let arr = (0, _object.get)(config.data, table.name);
        if (!arr) {
          arr = (0, _array.A)();
          (0, _object.set)(config.data, table.name, arr);
        }
        arr.push(object);
      });
      promises.push(promise);
    });
    yield _rsvp.Promise.all(promises);
    return config;
  }), _dec4 = (0, _emberConcurrency.task)(function* (config) {
    let {
      databaseName,
      version,
      stores,
      data
    } = config;
    (0, _log.log)('====================================');
    (0, _log.log)('Importing database dump!');
    (0, _log.log)('Dropping existing database...');
    yield (0, _object.get)(this, 'dropDatabaseTask').perform();
    (0, _log.log)(`Setting up database ${databaseName} in version ${version}...`);
    let db = new _dexie.default(databaseName);
    db.version(version).stores(stores);
    (0, _log.log)('Opening database...');
    yield openDb(db);
    let tables = Object.keys(data);
    while (tables.length) {
      let table = tables.shift();
      (0, _log.log)(`Importing ${data[table].length} rows for ${table}...`);
      yield db[table].bulkPut(data[table]);
    }

    // This is closed here, don't forget to call 'setup' again, to do eventually necessary migrations
    yield closeDb(db);
    (0, _log.log)('Database import done!');
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    while ((0, _object.get)(this, '_promiseQueue.length') || (0, _object.get)(this, '_savePromise')) {
      yield (0, _emberConcurrency.timeout)(100);
    }
  }), (_class = class IndexedDbService extends _service.default {
    /**
     * The actual Dexie database.
     *
     * @property db
     * @type {Dexie}
     * @public
     */

    /**
     * The database name to use.
     * Overwrite this if you want to use something different.
     *
     * @property databaseName
     * @type {String}
     * @default 'ember-indexeddb'
     * @public
     */

    /**
     * This is an object with an array per model type.
     * It holds all the objects per model type that should be bulk saved.
     * After actually saving, this will be cleared.
     *
     * @property _saveQueue
     * @type {Object}
     * @private
     */

    /**
     * This is the test waiter used to ensure all promises are resolved in tests.
     * This is set by the this._registerTestWaiter() method.
     *
     * @property _testWaiter
     * @type {Function}
     * @private
     */

    /**
     * This is a promise that is used for bulk saving.
     * All bulkSave() operations use and return the same promise, which is cached here.
     *
     * @property _savePromise
     * @type {Promise}
     * @private
     */

    /**
     * All currently running promises are temporarily saved here.
     * This is used to check if there are running transactions.
     *
     * @property _promiseQueue
     * @type {Promise[]}
     * @private
     */

    /**
     * e.g. MS Edge doesn't support compound indices.
     * For these cases, querying shouldn't try to use them.
     *
     * @property _supportsCompoundIndicies
     * @type {Boolean}
     * @readOnly
     * @private
     */

    /**
     * Call this and wait until it resolves before doing anything with IndexedDB!
     * This should be done in beforeModel() on the application route.
     * It will reject if IndexedDB is not available.
     *
     * Also available as a task: `indexedDb.setupTask.perform()`
     *
     * @method setup
     * @return {Promise}
     * @public
     */
    setup() {
      this.setupTask.perform();
    }
    /**
     * Query indexedDB.
     * This uses _buildQuery under the hood.
     * This resolved to an array.
     *
     * @method query
     * @param {String} type The model type to query
     * @param {Object} query The query data
     * @return {Promise[]}
     * @public
     */
    query(type, query) {
      let queryPromise = this._buildQuery(type, query);
      let promise = new _rsvp.Promise((resolve, reject) => queryPromise.toArray().then(resolve, reject), 'indexedDb/query');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Query indexedDB.
     * This uses _buildQuery under the hood.
     * This resolved to an object.
     *
     * @method queryRecord
     * @param {String} type The model type to query
     * @param {Object} query The query data
     * @return {Promise}
     * @public
     */
    queryRecord(type, query) {
      let queryPromise = this._buildQuery(type, query);
      let promise = new _rsvp.Promise((resolve, reject) => queryPromise.first().then(resolve, reject), 'indexedDb/queryRecord');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Find one or multiple items by id.
     * If id is an array, this will try to fetch all of these objects and resolve with an array.
     * Otherwise, it will resolve with an object.
     *
     * @method find
     * @param {String} type The model type to find
     * @param {String|String[]} id One or multiple ids to fetch
     * @return {Promise}
     * @public
     */
    find(type, id) {
      let db = (0, _object.get)(this, 'db');
      if ((0, _utils.typeOf)(id) === 'array') {
        return db[type].where('id').anyOf(id.map(this._toString)).toArray();
      }
      let promise = new _rsvp.Promise((resolve, reject) => db[type].get(this._toString(id)).then(resolve, reject), 'indexedDb/find');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Find all of a given type.
     *
     * @method findAll
     * @param {String} type The model type to find.
     * @return {Promise}
     * @public
     */
    findAll(type) {
      let db = (0, _object.get)(this, 'db');
      let promise = new _rsvp.Promise((resolve, reject) => db[type].toArray().then(resolve, reject), 'indexedDb/findAll');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Add one or multiple items to the database.
     *
     * @method add
     * @param {String} type The model type to add
     * @param {Object|Object[]} items One or multiple objects to add to the database.
     * @return {Promise}
     * @public
     */
    add(type, items) {
      let db = (0, _object.get)(this, 'db');

      // Single Item?
      if ((0, _utils.typeOf)(items) !== 'array') {
        items = (0, _array.A)([items]);
      }
      let data = items.map(item => {
        return this._mapItem(type, item);
      });
      let promise = new _rsvp.Promise((resolve, reject) => db[type].bulkPut(data).then(resolve, reject), 'indexedDb/add');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Save/update an item.
     *
     * @method save
     * @param {String} type The model type of the object
     * @param {String} id The id of the object
     * @param {Object} item The serialized object to save.
     * @return {Promise}
     * @public
     */
    save(type, id, item) {
      let db = (0, _object.get)(this, 'db');
      let data = this._mapItem(type, item);
      let promise = new _rsvp.Promise((resolve, reject) => db[type].put(data).then(resolve, reject), 'indexedDb/save');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * This will wait for 10ms and try to build a queue, and save everything at once if possible.
     *
     * @method saveBulk
     * @param {String} type The model type to save
     * @param {Object} item The data to save
     * @return {Promise}
     * @public
     */
    saveBulk(type, item) {
      let savePromise = (0, _object.get)(this, '_savePromise');
      let saveQueue = (0, _object.get)(this, '_saveQueue');

      // If no save promise exists, create a new one
      if (!savePromise) {
        savePromise = new _rsvp.Promise((resolve, reject) => {
          (0, _runloop.later)(this, () => {
            this._bulkSave().then(val => {
              (0, _object.set)(this, '_savePromise', null);
              resolve(val);
            }, reject);
          }, 100);
        }, 'indexedDb/saveBulk');
        (0, _object.set)(this, '_savePromise', savePromise);
        this._addToPromiseQueue(savePromise);
      }
      let queue = (0, _object.get)(saveQueue, type);
      if (!queue) {
        queue = (0, _array.A)();
        saveQueue[type] = queue;
      }
      queue.pushObject(item);
      return savePromise;
    }

    /**
     * Clear a database table.
     *
     * @method clear
     * @param {String} type The model type to clear.
     * @return {Promise}
     * @public
     */
    clear(type) {
      let db = (0, _object.get)(this, 'db');
      let promise = new _rsvp.Promise((resolve, reject) => db[type].clear().then(resolve, reject), 'indexedDb/clear');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Delete one item.
     *
     * @method delete
     * @param {String} type The model type to delete
     * @param {String} id The id of the entry to delete
     * @return {Promise}
     * @public
     */
    delete(type, id) {
      let db = (0, _object.get)(this, 'db');
      let promise = new _rsvp.Promise((resolve, reject) => db[type].delete(id).then(resolve, reject), 'indexedDb/delete');
      this._addToPromiseQueue(promise);
      return promise;
    }

    /**
     * Drop the entire database.
     *
     * Also available as a task: `indexedDb.dropDatabaseTask.perform()`
     *
     * @method dropDatabase
     * @return {Promise}
     * @public
     */
    dropDatabase() {
      return (0, _object.get)(this, 'dropDatabaseTask').perform();
    }
    /**
     * Export a complete dump of the current database.
     * The output of this can be used to recreate the exact database state via this.importDatabase(config);
     *
     * Also available as a task: `indexedDb.exportDatabaseTask.perform()`
     *
     * @method exportDatabase
     * @return {Promise}
     * @public
     */
    exportDatabase() {
      let promise = (0, _object.get)(this, 'exportDatabaseTask').perform();
      this._addToPromiseQueue(promise);
      return promise;
    }
    /**
     * Import a complete database dump as created by this.exportDatabase()
     *
     * Also available as a task: `indexedDb.importDatabaseTask.perform()`
     *
     * @method importDatabase
     * @param {Object} config A configuration object as created by this.exportDatabase()
     * @return {Promise}
     * @public
     */
    importDatabase(config) {
      let promise = (0, _object.get)(this, 'importDatabaseTask').perform(config);
      this._addToPromiseQueue(promise);
      return promise;
    }
    /**
     * Wait for all queued objects ot be resolved.
     * This will resolve when there are no open processes anymore.
     *
     * Also available as a task: `indexedDb.waitForQueueTask.perform()`
     *
     * @method waitForQueue
     * @return {Promise}
     * @public
     */
    waitForQueue() {
      return (0, _object.get)(this, 'waitForQueueTask').perform();
    }
    /**
     * Get the queue and save everything in it in bulk.
     *
     * @method _bulkSave
     * @private
     */
    _bulkSave() {
      let saveQueue = (0, _object.get)(this, '_saveQueue');
      let promises = (0, _array.A)();
      for (let i in saveQueue) {
        promises.push(this.add(i, saveQueue[i]));
        saveQueue[i] = (0, _array.A)();
      }
      return _rsvp.Promise.all(promises, 'indexedDb/_bulkSave');
    }

    /**
     * Build a query for Dexie.
     *
     * This will try to find a matching index, and use it if possible.
     * It can also handle multi-indecies, if they have been specified and are supported.
     * If no matching index is found, it will fetch everything and try to filter it via JS.
     * Note that this is _much_ slower than querying by actual indecies, so you should definitely use that if possible!
     * If you are using multiple query arguments, and only one of them is found as index, it will query the database by this index
     * and then do the other queries via JS filter.
     *
     * Note that this will also auto-convert boolean query arguments to 1/0.
     *
     * @method _buildQuery
     * @param {String} type The model type to query
     * @param {Object} query The actual query
     * @return {Dexie.Collection}
     * @private
     */
    _buildQuery(type, query) {
      let db = (0, _object.get)(this, 'db');
      let _supportsCompoundIndices = (0, _object.get)(this, '_supportsCompoundIndices');
      let promise = null;
      let keys = Object.keys(query);

      // Convert boolean queries to 1/0
      for (let i in query) {
        if ((0, _utils.typeOf)(query[i]) === 'boolean') {
          query[i] = query[i] ? 1 : 0;
        }
      }

      // Only one, then do a simple where
      if (keys.length === 1) {
        let key = keys[0];
        return db[type].where(key).equals(query[key]);
      }

      // Order of query params is important!
      let {
        schema
      } = db[type];
      let {
        indexes
      } = schema;

      // try to find a fitting multi index
      // only if the client supports compound indices!
      if (_supportsCompoundIndices) {
        let multiIndex = indexes.find(index => {
          let keyPath = (0, _object.get)(index, 'keyPath');

          // If keyPath is not set, not an array or not the same length as the keys, it's not the correct one
          if (!keyPath || (0, _utils.typeOf)(keyPath) !== 'array' || keyPath.length !== keys.length) {
            return false;
          }

          // If one of the keys is not in the keyPath, return false
          return !keys.find(key => !keyPath.includes(key));
        });

        // If a multi index is found, use it
        if (multiIndex) {
          let keyPath = (0, _object.get)(multiIndex, 'keyPath');
          let compareValues = (0, _array.A)();
          keyPath.forEach(key => {
            compareValues.push(query[key]);
          });
          let keyName = (0, _object.get)(multiIndex, 'name');
          return db[type].where(keyName).equals(compareValues);
        }
      }

      // Else, filter manually
      Object.keys(query).forEach(i => {
        if (!promise) {
          promise = db[type].where(i).equals(query[i]);
        } else {
          promise = promise.and(item => (0, _object.get)(item, i) === query[i]);
        }
      });
      return promise;
    }
    _mapItem(type, item) {
      let dbConfiguration = (0, _object.get)(this, 'indexedDbConfiguration');
      return dbConfiguration.mapItem(type, item);
    }
    _toString(val) {
      return `${val}`;
    }

    /**
     * Add a promise to the promise queue.
     * When the promise resolves or rejects, it will be removed from the promise queue.
     *
     * @method _addToPromiseQueue
     * @param {Promise} promise
     * @return {Promise}
     * @private
     */
    _addToPromiseQueue(promise) {
      let promiseQueue = (0, _object.get)(this, '_promiseQueue');
      promiseQueue.pushObject(promise);
      let removeObject = () => {
        promiseQueue.removeObject(promise);
      };
      promise.finally(removeObject);
      return promise;
    }

    /**
     * Register the test waiter.
     * This waiter checks if there are no promises left in the _promiseQueue.
     *
     * @method _registerTestWaiter
     * @private
     */
    _registerTestWaiter() {
      let testWaiter = () => {
        return (0, _object.get)(this, '_promiseQueue.length') === 0;
      };
      (0, _testWaiter.registerWaiter)(testWaiter);
      (0, _object.set)(this, '_testWaiter', testWaiter);
    }

    /**
     * This removes the test waiter.
     *
     * @method _unregisterTestWaiter
     * @private
     */
    _unregisterTestWaiter() {
      let testWaiter = (0, _object.get)(this, '_testWaiter');
      (0, _testWaiter.unregisterWaiter)(testWaiter);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "indexedDbConfiguration", _descriptor2, this);
      _defineProperty(this, "db", void 0);
      _defineProperty(this, "databaseName", 'ember-indexeddb');
      _defineProperty(this, "_saveQueue", {});
      _defineProperty(this, "_testWaiter", void 0);
      _defineProperty(this, "_savePromise", void 0);
      _defineProperty(this, "_promiseQueue", (0, _array.A)());
      _defineProperty(this, "_supportsCompoundIndices", true);
      _initializerDefineProperty(this, "setupTask", _descriptor3, this);
      _initializerDefineProperty(this, "dropDatabaseTask", _descriptor4, this);
      _initializerDefineProperty(this, "exportDatabaseTask", _descriptor5, this);
      _initializerDefineProperty(this, "importDatabaseTask", _descriptor6, this);
      _initializerDefineProperty(this, "waitForQueueTask", _descriptor7, this);
      try {
        window.IDBKeyRange.only([1]);
      } catch (e) {
        this._supportsCompoundIndices = false;
      }
      this._registerTestWaiter();
    }
    willDestroy() {
      this._unregisterTestWaiter();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "indexedDbConfiguration", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "setupTask", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dropDatabaseTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "exportDatabaseTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "importDatabaseTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "waitForQueueTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexedDbService;
  async function openDb(db) {
    while (!db.isOpen()) {
      await db.open();
    }
    return db;
  }
  async function closeDb(db) {
    while (db.isOpen()) {
      await db.close();
    }
    return db;
  }
});